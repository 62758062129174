import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Header from "../../components/header";
import "./index.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    <div className="sticky top-0 z-50 bg-white">
      <Header siteTitle="data chimp" beta />
    </div>
    <div style={{
    margin: `0 auto`,
    padding: `var(--size-gutter)`,
    height: "100vh"
  }}>
      <main className="relative prose px-10">{children}</main>
      <footer style={{
      marginTop: `var(--space-5)`,
      fontSize: `var(--font-sm)`
    }}>
        {
        /* © {new Date().getFullYear()} */
      }
      </footer>
    </div>
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "contents"
    }}><a parentName="h1" {...{
        "href": "#contents"
      }}>{`Contents`}</a></h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#getting-started"
        }}>{`Getting Started`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#automations"
        }}>{`Automations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modes"
        }}>{`Modes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#supported-packages"
        }}>{`Supported Packages`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#analyzing-private-data"
        }}>{`Analyzing Private Data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#data-security"
        }}>{`Data Security`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#troubleshooting"
        }}>{`Troubleshooting`}</a></li>
    </ul>
    <h1 {...{
      "id": "getting-started"
    }}><a parentName="h1" {...{
        "href": "#getting-started"
      }}>{`Getting Started`}</a></h1>
    <h2 {...{
      "id": "0-create-a-data-chimp-account"
    }}><a parentName="h2" {...{
        "href": "#0-create-a-data-chimp-account"
      }}>{`0. Create a data chimp account`}</a></h2>
    <a target="_blank" rel="noreferrer" className="text-black no-underline text-lg px-7 py-3 font-bold rounded-full hover:bg-yellow-400 bg-yellow-200 border-yellow-200 hover:border-yellow-400" href="https://the.datachimp.app/signup">
  Create Account
    </a>
    <p>{`I've listed my personal cell
number in the nav. Call me if you run into any issues. Email works too: `}<a parentName="p" {...{
        "href": "mailto:matt@datachimp.app"
      }}>{`matt@datachimp.app`}</a></p>
    <h2 {...{
      "id": "1-clone-the-starter-repo"
    }}><a parentName="h2" {...{
        "href": "#1-clone-the-starter-repo"
      }}>{`1. Clone the Starter Repo`}</a></h2>
    <p>{`data chimp visualizations are generated in the cloud and rendered in
your browser. To ensure our cloud environment matches your local one,
we use `}<a parentName="p" {...{
        "href": "https://rstudio.github.io/renv/index.html",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`renv`}</a>{` with pinned R package versions.`}</p>
    <p>{`The easiest way to ensure your packages our compatible with data chimp is to `}<a parentName="p" {...{
        "href": "https://github.com/getdatachimp/starter-repo-r",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`clone our starter repository`}</a>{` and
to run `}<inlineCode parentName="p">{`renv::restore()`}</inlineCode>{` once you've opened the project.`}</p>
    <h2 {...{
      "id": "2-follow-the-in-app-instructions-to-complete-the-setup-with-your-new-project"
    }}><a parentName="h2" {...{
        "href": "#2-follow-the-in-app-instructions-to-complete-the-setup-with-your-new-project"
      }}>{`2. Follow the in-app instructions to complete the setup with your new project.`}</a></h2>
    <p>{`The setup only involves running two functions and the app will give you the code to execute.`}</p>
    <h2 {...{
      "id": "3-youre-done-analyze-data-faster"
    }}><a parentName="h2" {...{
        "href": "#3-youre-done-analyze-data-faster"
      }}>{`3. You're done! Analyze data faster!`}</a></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fa4181a3d5005d24686421dd86ac6b19/4b190/fast_monkey.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABBAP/2gAMAwEAAhADEAAAAelOmViqCcB2Bf/EABoQAQEAAgMAAAAAAAAAAAAAAAEAEBESITH/2gAIAQEAAQUCvGel1uQbiOP/xAAZEQADAAMAAAAAAAAAAAAAAAACAxABETH/2gAIAQMBAT8BWsSVybzP/8QAGBEAAwEBAAAAAAAAAAAAAAAAAhARAxL/2gAIAQIBAT8BPTk5VF//xAAaEAACAgMAAAAAAAAAAAAAAAAAEQEQEiEx/9oACAEBAAY/AlApptDydbOV/8QAHBABAQACAgMAAAAAAAAAAAAAAREAECExQVFh/9oACAEBAAE/IWrQHuuLzl+mjAj3c723iujYLiaIdf/aAAwDAQACAAMAAAAQyy9+/8QAGxEBAAICAwAAAAAAAAAAAAAAAQARECExcdH/2gAIAQMBAT8QAJZ33fkStQLhx//EABkRAAIDAQAAAAAAAAAAAAAAAAABEBEhQf/aAAgBAgEBPxDVUE70aPkf/8QAGxABAQEBAQADAAAAAAAAAAAAAREAMVEQkaH/2gAIAQEAAT8Qnx5Swnn6aoRPAQ7H6T4sycU6M0SXgDZhoOBDI2OlGeJghDf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "monkey driving fast car",
            "title": "monkey driving fast car",
            "src": "/static/fa4181a3d5005d24686421dd86ac6b19/4b190/fast_monkey.jpg",
            "srcSet": ["/static/fa4181a3d5005d24686421dd86ac6b19/f93b5/fast_monkey.jpg 300w", "/static/fa4181a3d5005d24686421dd86ac6b19/b4294/fast_monkey.jpg 600w", "/static/fa4181a3d5005d24686421dd86ac6b19/4b190/fast_monkey.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    {
      /* ## For Exising Projects
      ### 1. Install the data chimp RStudio plugin in your exising project
      data chimp generates contextual visualizations by watching the R code
      you're writing in RStudio. It does this via a plugin, which you
      install by running:
      ```
      devtools::install_github("getdatachimp/datachimpRStudioPlugin@main")
      ```
      ### 2. Follow the in-app instructions to complete the setup with your existing project.
      The setup only involves running two functions and the app will give you the code to execute.
      ### 3. You're done! Analyze data faster! */
    }
    <hr></hr>
    <h1 {...{
      "id": "automations"
    }}><a parentName="h1" {...{
        "href": "#automations"
      }}>{`Automations`}</a></h1>
    <p>{`Automations are snippets of visualization-generating R code that will
run automatically while you are analyzing your data in RStudio. Here's
an example automation that shows the percentage of missing values for
all columns in a data frame:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/80558b2e10f3846383b3b69189042bb3/5a190/example-automation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABN0lEQVQoz2WRWW7jMBQEdf8jZrPHsrhvj4uSfNVATGw4yUeBANlsdvMttVVaE1qtjDHYxz4Z3+z7PvfH495dM+6ag94Hi9SE9RecupAlkLIjJk+WL2JylFLIxc/zg5gMMVtyyRSJU5+coVVh6b1jw4r2Jzbzxqqf2MwJdXnBB4t16utC8cToyCVQJE1aq0jNlJKoJdNbYzliH4ZX84R2K9frKyFcSTGglWHbFFksKUeitdSc+Pj4ZH9/Z/z5jsEy+s3wmVW9otUJZxXSEqlYQtJYr2bNLJEkDus10RokxWl0tLwxKxt/Rrszmzmj7D+MvxCSISSFtivGaqQWapOJVKGKzMpHqsPjts7Km31hs88oe2Jdz9TavquM++THfao/eTSblW9Rj9dar4iUB9Ggj/6j0m9+J/wP3yBgZlxy53MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "example automation code",
            "title": "example automation code",
            "src": "/static/80558b2e10f3846383b3b69189042bb3/5a190/example-automation.png",
            "srcSet": ["/static/80558b2e10f3846383b3b69189042bb3/5a46d/example-automation.png 300w", "/static/80558b2e10f3846383b3b69189042bb3/0a47e/example-automation.png 600w", "/static/80558b2e10f3846383b3b69189042bb3/5a190/example-automation.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<inlineCode parentName="p">{`tbl`}</inlineCode>{` variable is set to whatever data frame you are
analyzing within RStudio. data chimp also has several special
functions you can invoke within your automations for creating
visualizations for all columns within a data frame:`}</p>
    <h2 {...{
      "id": "plot_numeric_columns"
    }}><a parentName="h2" {...{
        "href": "#plot_numeric_columns"
      }}>{`plot_numeric_columns`}</a></h2>
    <p>{`Used for creating a visualization for every numeric column within a data
frame. Example usage:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "852px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8f61e9246e9c7559a20881b82258d3e9/47ff6/example_plot_numeric_columns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA30lEQVQY04WRyW7EIBBE/f+fmGWiTAzGYOgFjI8V0R5HSaQoh6deqG4KMdWq0Cq4oihbLkqWMxcokVH5UQ99q3Z+6gStVdRaMbFkhPSOuM1w4QWpeIgWbGVByg7r6sF+ATmH4hzCcsdWAoizaX18Q0wBWhn7vmPa9w6WYkvPxQtIMgpl62cOSDlCVG2g927xpD8469baWDhEB+bwbLjlBr/ecPdPmJdXfLgbUlrBwjbwHxNRBlEBm6to1oezwhGFEkQqjuP44eKKF9/7k6pAq5rLwXhCH3zVfw//vmR8yieMGclSNTX3QgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "plot numeric columns example code",
            "title": "plot numeric columns example code",
            "src": "/static/8f61e9246e9c7559a20881b82258d3e9/47ff6/example_plot_numeric_columns.png",
            "srcSet": ["/static/8f61e9246e9c7559a20881b82258d3e9/5a46d/example_plot_numeric_columns.png 300w", "/static/8f61e9246e9c7559a20881b82258d3e9/0a47e/example_plot_numeric_columns.png 600w", "/static/8f61e9246e9c7559a20881b82258d3e9/47ff6/example_plot_numeric_columns.png 852w"],
            "sizes": "(max-width: 852px) 100vw, 852px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "plot_numeric_column_combinations"
    }}><a parentName="h2" {...{
        "href": "#plot_numeric_column_combinations"
      }}>{`plot_numeric_column_combinations`}</a></h2>
    <p>{`Used for creating visualizations for every combination of numeric columns
within a data frame. Example usage:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/66b5af15a327d116799bf1284ff37123/5a190/example_plot_numeric_column_combinations.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/ElEQVQoz22Qy07FMAxE+/+/xwYWqKivNE3SOM4LVoPi9N4iYDEd25VjHw8cGRwJMTICe/EYwzNuzkxgqRGYCJEI7EnylBJyLsg5STxQcDBug/Ma2s44yUh82PmqTTBuhfUK+ljglgV+XcW3/QP23GVozlk0tE/JRVTrV49LRSmf4vXyZ1ybqsieGyb1inF5gbH6frBtYk8lamgdmzvilbezSM4BKUbB6/UgNak35MYfE2Pe37Dqd2x6xKJGaLNeuAqejGDvZoI++tAHYle5kdtUIt/vdCo4v8M5gxBYMHP62XQ3tm3+KCcMfWXu97luWUq5m3L6t/nx/7d/A7lQFpOf18A0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "plot numeric column combinations example code",
            "title": "plot numeric column combinations example code",
            "src": "/static/66b5af15a327d116799bf1284ff37123/5a190/example_plot_numeric_column_combinations.png",
            "srcSet": ["/static/66b5af15a327d116799bf1284ff37123/5a46d/example_plot_numeric_column_combinations.png 300w", "/static/66b5af15a327d116799bf1284ff37123/0a47e/example_plot_numeric_column_combinations.png 600w", "/static/66b5af15a327d116799bf1284ff37123/5a190/example_plot_numeric_column_combinations.png 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "plot_character_columns"
    }}><a parentName="h2" {...{
        "href": "#plot_character_columns"
      }}>{`plot_character_columns`}</a></h2>
    <p>{`Used for creating a visualization for every character or factor column within a data
frame. Example usage:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "886px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/99a83bba95c415879a382869537207c7/cc155/example_plot_character_columns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA40lEQVQoz4WR23LDIAxE/f+/mGmagm3uYBCQvm1GJJ7Wado+LAgkdo7ERESotT5EIBaVQ0yloLKIRsx3rbWv/Ki/e0ylbDBewIUVyl1gwwIfNdLm4MIC42c4tSIKgbQsMFJAO4ktJ/iooN0HlJHIOd0NW+uIm4Pxchj6YJBLQkoBW45DMcYDxd7N8fwg5OXaP7HaM2Z9grICF3mCtnIQMoW1GoUyaquj1b807QETLuYNNsyY1TuM0WD63q/ovY+aV0Q/CHkWIfrRAs+TSXhPKb58sBv/Sjh+k+gp0Q9U/7X53fAGO9wWyiieJNwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "plot character columns example code",
            "title": "plot character columns example code",
            "src": "/static/99a83bba95c415879a382869537207c7/cc155/example_plot_character_columns.png",
            "srcSet": ["/static/99a83bba95c415879a382869537207c7/5a46d/example_plot_character_columns.png 300w", "/static/99a83bba95c415879a382869537207c7/0a47e/example_plot_character_columns.png 600w", "/static/99a83bba95c415879a382869537207c7/cc155/example_plot_character_columns.png 886w"],
            "sizes": "(max-width: 886px) 100vw, 886px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "plot_date_numeric_column_combinations"
    }}><a parentName="h2" {...{
        "href": "#plot_date_numeric_column_combinations"
      }}>{`plot_date_numeric_column_combinations`}</a></h2>
    <p>{`Used for creating a visualization for every combination of date and numeric columns
within a data frame. Example usage:`}</p>
    <p><img parentName="p" {...{
        "src": "./plot_date_numeric_column_combinations.png",
        "alt": "plot date numeric column combinations example code"
      }}></img></p>
    <p className="text-white p-4 bg-red-200 font-bold">
  Note: Mutating data frames within the functions passed to{" "}
  <code>plot_numeric_columns</code>, <code>plot_character_columns</code>, and{" "}
  <code>plot_numeric_column_combinations</code> is not currently supported, but
  we're working to fix this soon!
    </p>
    <h1 {...{
      "id": "modes"
    }}><a parentName="h1" {...{
        "href": "#modes"
      }}>{`Modes`}</a></h1>
    <p>{`Modes are groups of automations that you want to run together while you're
analyzing data. For example, you may have a "Data Quality" mode that contains
one automation for showing the percentage of missing values for every column
and another automation that shows you the percentage of values for each column
that exceed an expected maximum value.`}</p>
    <p>{`You can view the most popular modes on datachimp `}<a className="text-black" href="https://the.datachimp.app/demo">{`here`}</a></p>
    <h1 {...{
      "id": "supported-packages"
    }}><a parentName="h1" {...{
        "href": "#supported-packages"
      }}>{`Supported Packages`}</a></h1>
    <p>{`Because data chimp visualizations are generated in the cloud, we cannot
support analyses that use packages that are not installed in our cloud
environment. The data chimp cloud environment currently only contains the
following packages:`}</p>
    <ul>
      <li parentName="ul">{`all packages from `}<a className="text-black" href="https://www.tidyverse.org/packages/">{`
the tidyverse metapackage`}
        </a>
      </li>
      <li parentName="ul">{`tidytuesdayR`}</li>
      <li parentName="ul">{`R.utils`}</li>
      <li parentName="ul">{`base64enc`}</li>
      <li parentName="ul">{`rlang`}</li>
    </ul>
    <p>{`We'll be adding many more packages soon, and ultimately, we'll make it
possible for you to upload an `}<code>{`renv.lock`}</code>{` file and get a data chimp
environment that matches you're local one. In the meantime, please let us know
which packages you need most.`}</p>
    <h1 {...{
      "id": "analyzing-private-data"
    }}><a parentName="h1" {...{
        "href": "#analyzing-private-data"
      }}>{`Analyzing Private Data`}</a></h1>
    <p>{`data chimp currently only supports the analysis of private data by uploading
csv files from your machine to data chimp. We're working on making it possible
to analyze data within internal databases via packages like DBI and dbplyr.`}</p>
    <p>{`To upload files to data chimp for further analysis, first call `}<inlineCode parentName="p">{`datachimpR::dc_upload("<file-path>")`}</inlineCode>{`. This uploads the file to data
chimp so you can read the data into memory via functions like `}<inlineCode parentName="p">{`readr::read_csv`}</inlineCode>{` and analyze it as you normally would. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "r"
    }}><pre parentName="div" {...{
        "className": "language-r"
      }}><code parentName="pre" {...{
          "className": "language-r"
        }}>{`datachimpR`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span>{`dc_upload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"<file-path>"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# [1] "fab717f1-6be4-4837-9a1a-c1ad9bd17c4c"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Note this id ^ and pass it to datachimpR::dc_download`}</span>{`

datachimpR`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span>{`dc_download`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fab717f1-6be4-4837-9a1a-c1ad9bd17c4c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

df `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<-`}</span>{` readr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span>{`read_csv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fab717f1-6be4-4837-9a1a-c1ad9bd17c4c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

df `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  group_by`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`chimp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  summarize`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`total_sessions `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` sum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sessions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h1 {...{
      "id": "data-security"
    }}><a parentName="h1" {...{
        "href": "#data-security"
      }}>{`Data Security`}</a></h1>
    <p>{`data chimp visualizations are generated in the cloud, which means that data
chimp does have temporary access to analysis code and the data the analysis
code is acting upon. Neither the code, nor the data is persisted on data chimp
servers long-term. They are only stored for the purposes of generating
visualizations in a sandboxed R environment. Once this R environment is
terminated (happens 24 hours after the user is no longer actively analyzing
data), the code and data will no longer be on data chimp servers.`}</p>
    <p>{`The code sent to data chimp is sent via a secure web socket (`}<code>{`wss`}</code>{`
), and data chimp gains access to the data it needs via the same token-based authentication
methods used for `}<a parentName="p" {...{
        "href": "https://dbplyr.tidyverse.org/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`dbplyr`}</a>{` database access or by uploading temporary, private csv files to data chimp
servers.`}</p>
    <h1 {...{
      "id": "troubleshooting"
    }}><a parentName="h1" {...{
        "href": "#troubleshooting"
      }}>{`Troubleshooting`}</a></h1>
    <h2 {...{
      "id": "timeout"
    }}><a parentName="h2" {...{
        "href": "#timeout"
      }}>{`Timeout`}</a></h2>
    <p>{`If its taking longer than 60 seconds to generate a set of visualiations, data chimp will abandon
the attempt to show them. This can happen if you're trying to show too many visualiations at once.`}</p>
    <p>{`This is easy to do with automations that use `}<inlineCode parentName="p">{`plot_numeric_column_combinations`}</inlineCode>{` since a data frame with
5 numberic columns will generate 5! visualiations. To avoid time out errors, try `}<inlineCode parentName="p">{`dplyr::select`}</inlineCode>{` to select
just a few columns you're interested in.`}</p>
    <h2 {...{
      "id": "no-results"
    }}><a parentName="h2" {...{
        "href": "#no-results"
      }}>{`No results`}</a></h2>
    <p>{`If you see a no results error (with a confused looking monkey), there are a few possible causes:`}</p>
    <ol>
      <li parentName="ol">{`You may not have any automations configured for the mode you've selected on the analysis screen. Check the mode by going to "My modes" and ensure an automation is associated with the mode.`}</li>
      <li parentName="ol">{`There may be a bug with your automation code. We're working on building debugging and testing in to data chimp, but for now, you can paste your code into any R session and ensure that it works. See `}<a parentName="li" {...{
          "href": "#automations"
        }}>{`the automation section`}</a>{` for more on how to create automations.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      